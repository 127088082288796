import { graphql } from 'gatsby';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import Markdown from 'markdown-to-jsx';
import React from 'react';

// Components
import { EmptyTable, FilterBar } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, RecordsTrackTable } from 'templates';

// Hooks
import { useIndoorAllTimeMeets, useOutdoorAllTimeMeets } from 'hooks';

// Misc.
import { orderBySeason } from 'utils';

// Types
import { MeetName, Record, Season } from 'types';
interface Props {
  data: {
    trackRecords: Record;
    pageContent: any;
  };
  pageContext: {
    meetName: MeetName;
    season: Season;
  };
}

const FILTERS = [{ title: 'Gender', gqlKey: 'gender' }];

const TrackAndFieldAllTimeRecordsTablePage = (props: Props) => {
  const { season } = props.pageContext;
  const { pageContent } = props.data;

  const outdoorMeets = {
    allTimeMeets: useOutdoorAllTimeMeets(),
  };

  const indoorMeets = {
    allTimeMeets: useIndoorAllTimeMeets(),
  };

  if (!season) return null;

  // Match the key name from QGL.
  const properSeason = camelCase(season);

  // Use the correct seasonMap.
  const seasonObj = properSeason === 'indoorTrack' ? indoorMeets : outdoorMeets;

  const records = { nodes: [...seasonObj.allTimeMeets] };

  const noRecords = records.nodes.length < 1;

  // Gather markdown content.
  const markdown = get(pageContent, 'nodes[0].data.markdown_content', '') || '';
  const templateTitle = `${startCase(season)} All time Records`;

  return (
    <SEOContent>
      <PageLayout>
        <BasicTemplate templateTitle={templateTitle}>
          {noRecords ? (
            <EmptyTable />
          ) : (
            <>
              <Markdown>{markdown}</Markdown>
              <FilterBar
                data={records}
                filters={FILTERS}
                render={(tableData) => (
                  <RecordsTrackTable
                    data={orderBySeason({ tableData, season })}
                    isAllTime={true}
                  />
                )}
              />
            </>
          )}
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default TrackAndFieldAllTimeRecordsTablePage;

export const query = graphql`
  {
    pageContent: allAirtable(filter: { table: { eq: "Record Table Page" } }) {
      nodes {
        data {
          markdown_content
          sponsors {
            data {
              sponsor_name
              sponsor_url
              attachments {
                localFiles {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
